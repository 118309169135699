export const getEnvMapApi = () => {
  return {
    'staging': import.meta.env.VITE_STAGING_MAP_API,
    'production': import.meta.env.VITE_PRODUCTION_MAP_API,
    'development': import.meta.env.VITE_DEVELOPMENT_MAP_API
  }[import.meta.env.MODE]
}

export const getEnvMapID = () => {
  return {
    'staging': import.meta.env.VITE_STAGING_MAP_ID,
    'production': import.meta.env.VITE_PRODUCTION_MAP_ID,
    'development': import.meta.env.VITE_DEVELOPMENT_MAP_ID
  }[import.meta.env.MODE]
}