import { GDouglasPeucker } from '@/utils/gDouglasPeucker';
import { useMapHelpers } from './useMapHelpers';
import type { CountyDropdown, Shape } from '@/types/counties';
import { fitPolygons } from '@/utils/fitPolygon';
import { mapOptions, polyOptions, mapDataOptions } from '@/modules/mapOptions'

let map: google.maps.Map;
let poly: google.maps.Polyline | null = null;

export function useGoogleMap () {
  const { 
    disable, 
    enable, 
    onShapeDrawn,
    drawOnMap
  } = useMapHelpers()

  const initialize = async () => {
    const { Map } = 
      await google.maps.importLibrary('maps') as google.maps.MapsLibrary;

    map = new Map(
      document.getElementById('map') as HTMLElement, 
      mapOptions,
    );
    
    map.data.setStyle(mapDataOptions)
    map.getDiv().addEventListener('mousedown', triggerDrawOnMap);
    map.getDiv().addEventListener('contextmenu', 
      function (event: MouseEvent) {
        event.preventDefault();
        if (!drawOnMap.value) return
        if (event.ctrlKey) {
          drawFreeHand(true); //Pass in true as params if from contextmenu
        }
    });

    window.dispatchEvent(new Event('mapLoaded'))
  }

  const triggerDrawOnMap = (event: MouseEvent) => {
    if (!drawOnMap.value) return
    if (event.ctrlKey) return
    if (event.button == 2) { // Right click
      drawFreeHand();
    }
  }

  const startPolyline = () => {
    if (poly) poly.setMap(null)
    poly = new google.maps.Polyline({ 
      map: map, clickable: false
    });
  }

  const getMapInstance = (): google.maps.Map => map
  
  let isDrawing = false;
  let initialDrawn = false;

  function drawFreeHand(contextMenu?: boolean) {
    isDrawing = true;
    disable(map); 
      
    let path: google.maps.MVCArray<google.maps.LatLng> | null | undefined = null

    const move = google.maps.event.addListener(
      map, 'mousemove', function (e: google.maps.MapMouseEvent) {
        if (! initialDrawn) {
          window.dispatchEvent(new Event('initial-drawn'))
          startPolyline()
          path = poly?.getPath();
          initialDrawn = true
        } 
        map.setOptions({ draggableCursor: 'crosshair' })
        const isMac = navigator.platform.toUpperCase().indexOf('MAC')>=0;

        if (isMac && ! (e.domEvent as MouseEvent).ctrlKey && contextMenu) {
          map.getDiv().dispatchEvent(new MouseEvent('mouseup'))
          return 
        }

        poly?.getPath().push(e?.latLng!);
    });
    
    const mouseUpListener = function () { //eslint-disable-line complexity
      if(! isDrawing) return;

      // Enable the map when drawing ends
      enable(map);
      isDrawing = false;
  
      google.maps.event.removeListener(move);
      map.getDiv().removeEventListener('mouseup', mouseUpListener);

      if (poly && initialDrawn) {
        poly.setMap(null)
      }

      initialDrawn = false

      let distance = 0;
      if (! path) return

      for (let i = 1; i < path!.getLength(); i++) {
        distance += google.maps.geometry.spherical.computeDistanceBetween(
          path!.getAt(i - 1), 
          path!.getAt(i)
        );
      }

      if (distance < 20) return;

      const theArrayofLatLng = path!.getArray();
      theArrayofLatLng.push(theArrayofLatLng[0]);

      const ArrayforPolygontoUse = GDouglasPeucker(theArrayofLatLng, 50);
      poly = new google.maps.Polygon(polyOptions(map, ArrayforPolygontoUse))

      // Trigger the onShapeDrawn event with the polygon
      onShapeDrawn(poly, true); 
      window.dispatchEvent(new Event('map-drawn'))
    };

    map.getDiv().addEventListener('mouseup', mouseUpListener);
  }

  const renderMapPolygon = (shape: Shape, forceSaveCampaign?: boolean) => {
    const coordinates: google.maps.LatLng[] = [];

    if (shape.type === 'MultiPolygon') {
     shape.coordinates.forEach((polygon: any) => {
        (polygon as number[][])[0].forEach((coord: any) => {
          coordinates.push(
            new google.maps.LatLng(coord[1], coord[0])
          )
        })
      }
     ) 
    } else if (shape.type === 'Polygon') {
      const shapeCoord: number[][] = shape.coordinates as number[][]
      shapeCoord[0].forEach((coord: any) => {
        coordinates.push(new google.maps.LatLng(coord[1], coord[0]))
      })
    }

    if (poly) poly.setMap(null)

    poly = new google.maps.Polygon(
      polyOptions(map, coordinates)
    )

    onShapeDrawn(poly, false, forceSaveCampaign); 
    fitPolygons(map, poly)
  }

  const mapPanTo = (data: Omit<CountyDropdown, 'state_name'>) => {
    if (!map) return 
    if (data.value?.lat && data.value?.lng) {
      map.panTo({
        lat: +data.value.lat!,
        lng: +data.value.lng!
      })
    }
  }

  const clearPolygon = () => {
    if (poly) poly.setMap(null)
  }

  const renderPolygon = (data?: {
      lat: number; lng: number }[]
    ) => {
    if (poly) poly.setMap(null)
    if (! Array.isArray(data) || !map || data.length === 0) return 

    const coordinates: google.maps.LatLng[] = []
    
    data.forEach(coord => {coordinates.push(
        new google.maps.LatLng(coord.lat, coord.lng))})

    poly = new google.maps.Polygon(polyOptions(map, coordinates))
    
    onShapeDrawn(poly)
    fitPolygons(map, poly)
  }

  return { 
    initialize, 
    getMapInstance, 
    clearPolygon,
    renderMapPolygon, 
    mapPanTo, 
    renderPolygon 
  }
}