import { POSITION, type PluginOptions } from 'vue-toastification';

export const toastOptions: PluginOptions = {
  position: POSITION.TOP_RIGHT,
  hideProgressBar: true,
  pauseOnHover: false,
  timeout: 3500,
  maxToasts: 1,
  newestOnTop: true,
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter(
      t => t.type === toast.type
    ).length !== 0) {
      // Returning false discards the toast
      return false;
    }
    // You can modify the toast if you want
    return toast;
  }
};