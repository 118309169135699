import { ref, reactive, computed } from 'vue'
import type { CampaignsResponseData } from '@/types/api-response/campaigns'
import type { 
  MapAdvancedFilters, Slopes 
} from '@/types/mapFilters'
import { useSettings } from './useSettings'
import type { FormSelectOptions } from '@/types/components/formSelect'
import type { 
  LandUseCodeGroup, 
  LandUseGroupItem 
} from '@/types/api-response/settings'

const advancedFilters = reactive<MapAdvancedFilters>({
  apn: '',
  out_of_state_owner: null,
  out_of_county_owner: null,
  flood_zones: null,
  mailing_opt_out: null,
  corporate_owner: null,
  multiple_owners: null,
  last_sale_date_from: '',
  last_sale_date_to: '',
  assessed_improvement_from: '',
  assessed_improvement_to: '',
  percentage_flat_from: null,
  percentage_flat_to: null,
  remove_duplicate_owners: null,
  exclude_campaign_lots: null,
  land_use_codes: [],
  slopes: []
})

const filteredLotCount = ref<number>(0)
const skipFetch = ref<boolean>(true)

export function useAdvancedFilters () {
  const settings = useSettings()

  const getAdvancedFilter = () => ({
    ...advancedFilters,
    land_use_codes: advancedFilters.land_use_codes?.map(
      code => code.code
    ),
    slopes: advancedFilters.slopes?.map(
      slope => slope.value
    )
  })
  //eslint-disable-next-line complexity
  const setAdvancedFilters = (campaign: CampaignsResponseData) => {
    skipFetch.value = true

    if (! Object.keys(campaign.attributes.lot_filters).length) {
      clearFilters()
      return
    } 
    advancedFilters.apn = campaign.attributes?.lot_filters?.apn!
    advancedFilters.out_of_state_owner = 
      campaign.attributes?.lot_filters?.out_of_state_owner! ?? null
    advancedFilters.out_of_county_owner = 
      campaign.attributes?.lot_filters?.out_of_county_owner! ?? null
    advancedFilters.flood_zones = 
      campaign.attributes?.lot_filters?.flood_zones! ?? null
    advancedFilters.mailing_opt_out = 
      campaign.attributes?.lot_filters?.mailing_opt_out! ?? null
    advancedFilters.multiple_owners = 
      campaign.attributes?.lot_filters?.multiple_owners! ?? null
    advancedFilters.last_sale_date_from = 
      campaign.attributes?.lot_filters?.last_sale_date_from!
    advancedFilters.last_sale_date_to = 
      campaign.attributes?.lot_filters?.last_sale_date_to!
    advancedFilters.percentage_flat_from = 
      campaign.attributes?.lot_filters?.percentage_flat_from!
    advancedFilters.percentage_flat_to = 
      campaign.attributes?.lot_filters?.percentage_flat_to!
    advancedFilters.corporate_owner = 
      campaign.attributes?.lot_filters?.corporate_owner! ?? null
    advancedFilters.assessed_improvement_from = 
      campaign.attributes?.lot_filters?.assessed_improvement_from!
    advancedFilters.assessed_improvement_to = 
      campaign.attributes?.lot_filters?.assessed_improvement_to!
    advancedFilters.remove_duplicate_owners = 
      campaign.attributes?.lot_filters?.remove_duplicate_owners! ?? null
    advancedFilters.exclude_campaign_lots =
      campaign.attributes?.lot_filters?.exclude_campaign_lots! ?? null

    //Clear land_use_codes first before setting
    //its value
    advancedFilters.land_use_codes = []
    settings.landUseCodes.value.forEach(
      (group: LandUseCodeGroup) => {
        advancedFilters.land_use_codes?.push(...group.items.filter(
          (code: LandUseGroupItem) => 
            campaign.attributes.lot_filters.land_use_codes?.includes(
              +code.code)))
        }
      )

    advancedFilters.slopes =
      settings.slopes.value.filter(
        (slope: Slopes) =>
          campaign.attributes?.lot_filters.slopes?.includes(
            slope.value
          )
      )
  }
  /**
   * DO NOT CLEAR THE EXCLUDED APNS
   * Users spend hours scrubbing lots and if they need to redo that 
   * work, then they will not be happy.
   */
  const clearFilters = () => {
    advancedFilters.apn = ''
    advancedFilters.land_use_codes = []
    advancedFilters.slopes = []
    advancedFilters.last_sale_date_from = ''
    advancedFilters.last_sale_date_to = ''
    advancedFilters.percentage_flat_from = null
    advancedFilters.percentage_flat_to = null
    advancedFilters.assessed_improvement_from = ''
    advancedFilters.assessed_improvement_to = ''
    advancedFilters.multiple_owners = null
    advancedFilters.corporate_owner = null
    advancedFilters.mailing_opt_out = null
    advancedFilters.flood_zones = null
    advancedFilters.out_of_state_owner = null
    advancedFilters.out_of_county_owner = null
    advancedFilters.remove_duplicate_owners = null
    advancedFilters.exclude_campaign_lots = null
    advancedFilters.out_of_county_owner = null
  }

  const validateImprovement = (): boolean | undefined => {
    if (advancedFilters.assessed_improvement_from && 
      advancedFilters.assessed_improvement_to) {
        if (advancedFilters.assessed_improvement_to 
            < advancedFilters.assessed_improvement_from) 
          return true
    } 
  }

  const dropdownOptions: FormSelectOptions[] = [
    { label: 'Any', value: null },
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ]

  const removeDuplicateOwnerOption: FormSelectOptions[] = [
    { label: 'Any', value: null },
    { label: 'Keep the largest lot', value: 'largest' },
    { label: 'Keep the smallest lot', value: 'smallest' },
  ]

  const excludeCampaignLotsOption: FormSelectOptions[] = [
    { label: 'None', value: null },
    { label: 'Remove by same owner name', value: 'owner_name' },
    { label: 'Remove by same APN', value: 'apn' },
  ]

  //eslint-disable-next-line complexity
  const getAppliedFiltersCount = computed(() => {
    let total = 0
    for (const key of Object.keys(advancedFilters)) {
      const typedKey = key as keyof typeof advancedFilters
      if (
          !(Array.isArray(advancedFilters[typedKey])) &&
          (advancedFilters[typedKey] !== null &&
          advancedFilters[typedKey] !== '' &&
          advancedFilters[typedKey] !== undefined)
        ) {
          total += 1
      }

      if (Array.isArray(advancedFilters[typedKey]) && 
      (advancedFilters[typedKey] as unknown as string[])!.length > 0) {
        total += 1
      }
    }

    return total
  })
  

  return { 
    advancedFilters,
    clearFilters, 
    dropdownOptions,
    filteredLotCount,
    getAdvancedFilter, 
    removeDuplicateOwnerOption,
    excludeCampaignLotsOption,
    setAdvancedFilters,
    skipFetch,
    validateImprovement,
    getAppliedFiltersCount
  }
}