<script setup lang="ts">
defineProps<{
  id: string
  value: string 
  label?: string
  dataCy?: string
}>()

const model = defineModel<boolean | string | number>()
</script>

<template>
  <div class="flex items-center w-fit">
    <div class="grid place-items-center">
      <input
        :id="`link-radio-${value}`"
        v-model="model"
        type="radio"
        :value="value"
        class="radio-btn peer"
        :data-cy="dataCy && `${dataCy}_radio`"
      />
      <div class="checked">
      </div>
    </div>
    <slot name="label">
      <label
        :for="`link-radio-${value}`"
        class="ms-2 text-sm text-gray-800 cursor-pointer"
      >
        {{ label }}
      </label>
    </slot>
  </div>
</template>

<style>
.radio-btn {
  @apply w-4 h-4 !text-primary-200 bg-gray-100 !border-gray-300 
  appearance-none shrink-0 col-start-1 row-start-1 checked:!bg-primary-100
  focus:!border-none focus:!ring-0 focus:!ring-primary-100
}

.checked {
  @apply col-start-1 row-start-1 w-1.5 h-1.5 rounded-full 
  peer-checked:!bg-white
}
</style>