export const enum ROUTE {
  HOME = 'Home',
  REGISTER = 'Register',
  LOGIN = 'Login',
  FORGOT_PASSWORD = 'Forgot Password',
  EXPLORE = 'Explore',
  CAMPAIGNS = 'Campaigns',
  SETTINGS = 'Settings',
  NOT_FOUND = 'Not Found',
  ACTIVATE_CAMPAIGN = 'Activate Campaign',
  UPDATE_CAMPAIGN = 'Update Campaign', 
  HELP = 'help',
  SUBSCRIBE = 'subscribe',
  TERMS_OF_USE = 'terms of use',
  ADMIN = 'admin',
  CREATE_CAMPAIGN = 'Create Campaign',
  ACCOUNT_SETTINGS = 'account settings',
  ACCOUNT_SUBSCRIPTION = 'account subscription',
  ACCOUNT_REFERRALS = 'account referrals',
  LOTHUNT_REPORT = 'lothunt report',
}

export const enum RESPONSE {
  SUCCESS = 200,
  CREATED = 201,
  NO_CONTENT = 204,
  FOUND = 302,
  BAD_REQUEST = 400, 
  UNAUTHORIZED = 401,
  PAYMENT_REQUIRED = 402,
  NOT_FOUND = 404,
  REQUESTED_TOO_SOON = 429,
  UNAVAILABLE_FOR_LEGAL_REASON = 451
}

export const enum LOADING {
  REGISTER = 'register',
  LOGIN = 'login',
  VERIFY = 'verify',
  FORGOT_PASSWORD = 'forgot password',
  UPDATE_PASSWORD = 'update password',
  GET_TEMPLATES = 'get templates',
  DELETE_TEMPLATE = 'delete template',
  ACTIVATING_CAMPAIGN = 'activating campaign',
  SUBMIT_FEEDBACK = 'submit feedback',
  GENERATING_PDF = 'generating pdf',
  CREATE_CAMPAIGN = 'create campaign',
  LOG_OUT = 'logout',
  UPDATE_TEMPLATE = 'update template',
  UPDATE_COMP_LOT = 'update comp-lot',
  UPDATE_MAILING_DETAILS = 'update mailing details',
  RESUME_CAMPAIGN = 'resume campaign',
  PAUSE_CAMPAIGN = 'pause campaign',
  UPDATE_USER = 'update user',
  UPDATE_CAMPAIGN = 'update campaign',
  INVITE_MEMBER = 'invite member',
  REMOVE_MEMBER = 'remove member',
  SET_MEMBER_STATUS = 'set member status',
  CHANGE_OWNERSHIP = 'change ownership',
  SAVE_CAMPAIGN = 'save campaign',
  TERMS_ACCEPTED = 'terms accepted',
  FETCHING_LOTS = 'fetching lots',
  FETCHING_COMPS = 'fetching comps',
  UPDATE_PAYMENT = 'update payment',
  CANCEL_SUBSCRIPTION = 'cancel subscription',
  SUBSCRIBING_PLAN = 'subscribing plan',
  ADD_PAYMENT_METHOD = 'add payment method',
  GET_PAYMENTS = 'get payments',
  MANAGE_PLANS_AND_PAYMENT = 'manage plans and payment',
  GET_CAMPAIGN = 'get campaign',
  GET_PLACEHOLDERS = 'get placeholders',
  GET_LOT_COUNT = 'get lot count',
  GET_AUTH_USER = 'get auth user',
  UPLOAD_CSV_CREATE_CAMPAIGN = 'upload csv',
  GET_PARCEL_LOTS = 'get parcel lots',
  LOTHUNT_REPORT = 'lothunt report',
  DOWNLOAD_LOTS = 'download lots',
  SAVE_FROM_DOWNLOAD = 'save from download',
  DOWNLOAD_DOC = 'download doc',
  UPLOADING_DOC = 'uploading doc',
  GET_ALL_CAMPAIGNS = 'get all campaigns'
}

export type MapFilterValue = typeof MAPFILTERS[keyof typeof MAPFILTERS]

export const enum MAPFILTERS {
  LOTS = 1,
  COMPS_SALE = 2,
  COMPS_SOLD = 3
}

export const enum CAMPAIGN_STEP {
  UPLOAD_CSV = 1,
  MAILING_DETAILS = 2,
  CHOOSE_TEMPLATE = 3,
  LETTER_PREVIEW = 4
} 