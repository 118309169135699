<script setup lang="ts">
import { computed, watch } from 'vue';
import { Icon } from '@iconify/vue/dist/iconify.js';

const modal = defineModel<boolean>()

const props = withDefaults(defineProps<{
  dismissable?: boolean;
  title?: string;
  noPadding?: boolean
  bgColor?: 'warning' | 'default'
  containerClass?: string
  minWidth?: 'md' | 'lg'
  fullHeight?: boolean
  dataCy?: string
}>(), {
  dismissable: true,
  title: '',
  noPadding: false,
  bgColor: 'default',
  containerClass: '',
  minWidth: 'md',
  fullHeight: false
})

const emits = defineEmits<{
  (e: 'close-modal'): void 
}>()

const dismissModal = () => {
  if (!props.dismissable) return 
  modal.value = false
}

const closeModal = () => {
  emits('close-modal')
  modal.value = false
}

watch(
  () => modal.value,
  () => {
    if (modal.value) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }
)

const bgClass = computed(
  () => ({
    'warning': 'bg-[#FFEFE4]',
    'default': 'bg-white'
  }[props.bgColor ?? 'default'])
)

const minWidth = computed(
  () => ({
    'md': 'md:min-w-[480px]',
    'lg': 'md:min-w-[600px]'
  }[props.minWidth])
)
</script>

<template>
  <div
    v-if="modal" 
    class="h-dvh w-full fixed inset-0 z-50"
    :data-cy="dataCy && `${dataCy}_cModal`"
  >
    <div 
      class="w-full h-dvh transition-opacity inset-0 z-10 bg-gray-300/75"
      @click="dismissModal"
    ></div>
    <div
      class="modal-container" 
      :class="[
        containerClass,
        {'max-h-full': props.fullHeight}
      ]"
    >
      <div 
        class="relative rounded-md w-full shadow"
        :class="[{'p-2': !noPadding}, bgClass, minWidth]"
      >
        <div
          class="absolute top-5 right-6 cursor-pointer z-10"
          :data-cy="dataCy && `${dataCy}_closeButton`"
          @click="closeModal"
        >
          <Icon
            icon="ic:baseline-close" 
            class="cursor-pointer"
            width="1.2em"
          />
        </div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style>
.modal-container {
  @apply fixed top-1/2 transition-all -translate-y-1/2 
  left-1/2 -translate-x-1/2 flex justify-center md:p-4 
  sm:px-4 w-full md:max-w-md px-4;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>