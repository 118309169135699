const formatCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const numberFormat = (value: number | null) => value ?
  formatCurrency.format(value) : '-'

/**
 * (value: number) is the number that needs to be formatted, 
 * (shorten?: boolean) formats the number with a prefix of $ and a suffix of 
 * M  or K depending on the value.
 */ 
export const format = (value: number | null, shorten?: boolean) => {
  if (! shorten) return numberFormat(value)
  // Helper function to format number with one decimal place
  const formatNumber = (num: number): string => 
    num?.toFixed(1).replace(/\.0$/, '');

  if (value! >= 1_000_000) {
    return `${formatNumber(value! / 1_000_000)}M`;
  } else if (value! >= 1_000) {
    return `${formatNumber(value! / 1_000)}K`;
  } else {
    return `${value!.toFixed(2)}`;
  }
}

export const formatRange = (
  value1: number | null, 
  value2: number | null, 
  shorten?: boolean
) => {
  const formatNumber = (num: number): string =>
    num?.toFixed(1).replace(/\.0$/, '');

  const formatWithShorten = (value: number): string => {
    if (value >= 1_000_000) {
      return `${formatNumber(value / 1_000_000)}M`;
    } else if (value >= 1_000) {
      return `${formatNumber(value / 1_000)}K`;
    } else {
      return `${value?.toFixed(2)}`;
    }
  };

  const formatValue = (value: number | null): string =>
    value !== null ? `${value?.toFixed(2)}` : '';

  const formattedValue1 =
    shorten && value1 !== null ? 
      formatWithShorten(value1) : formatValue(value1);
  const formattedValue2 =
    shorten && value2 !== null ? 
      formatWithShorten(value2) : formatValue(value2);

  return `${formattedValue1}-${formattedValue2}`;
};
