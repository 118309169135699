export const convertToMonth = (month: string) => {
  return Intl.DateTimeFormat('en', { month: 'long' })
    .format(new Date(month))
}

export const dateUnitToDate = (payload: { 
  date: number | string, unit: string 
}) => {
  const now = new Date();
  const newDate = new Date(now);

  switch (payload.unit) {
    case 'days':
      newDate.setDate(now.getDate() - +payload.date);
      break;
    case 'weeks':
      newDate.setDate(now.getDate() - +payload.date * 7);
      break;
    case 'months':
      newDate.setMonth(now.getMonth() - +payload.date);
      break;
    case 'years':
      newDate.setFullYear(now.getFullYear() - +payload.date);
      break;
  }

  return newDate.toISOString().split('T')[0]; // Format as yyyy-MM-dd
}

export const dateToUnitDate = (date: Date | string) => {
  const givenDate = new Date(date);
  const now = new Date();
  
  // Calculate the difference in milliseconds
  const diffInMs: number = now.getTime() - givenDate.getTime();

  // Convert milliseconds to days
  const msPerDay = 1000 * 60 * 60 * 24;
  const days = Math.floor(diffInMs / msPerDay);

  // Convert days to months and years for a more readable result
  const msPerMonth = msPerDay * 30; // Approximation
  const msPerYear = msPerDay * 365; // Approximation
  const months = Math.floor(diffInMs / msPerMonth);
  const years = Math.floor(diffInMs / msPerYear);

  if (years >= 1) {
    return { date: years, unit: 'years' };
  } else if (months >= 1) {
    return { date: months, unit: 'months' };
  } else {
    return { date: days, unit: 'days' };
  }
}