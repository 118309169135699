import { getEnvMapApi } from '@/utils/getEnvMapApi';
import { Loader } from '@googlemaps/js-api-loader';

const loader = new Loader({
  apiKey: getEnvMapApi(),
  version: 'weekly',
});

export default async function loadMap() {
  await loader.importLibrary('maps')
}