import type { LatLng } from '@/types/api-response/campaign';

export function fitPolygons(
    map: google.maps.Map, 
    polygons: google.maps.Polyline
  ) {
   // Create a LatLngBounds object to contain the bounding box
   const bounds = new google.maps.LatLngBounds();

   // Iterate over each polygon and extend the bounds
   polygons.getPath().forEach(function(point: any) {
     bounds.extend(point);
   });

   setZoomAndCenter(map, bounds)
}

export const setZoomAndCenter = (
  map: google.maps.Map,
  bounds: google.maps.LatLngBounds,
  maxZoom: number = 15,
  zoomAdjustment: number = 0.65,
  latLngCenter?: LatLng,
  useLatLngZoom?: boolean
) => {
   const center = bounds.getCenter();
   // Fit the map to the bounding box
   map.fitBounds(bounds);

   // Calculate the zoom level based on the bounding box dimensions
   const ne = bounds.getNorthEast();
   const sw = bounds.getSouthWest();
   
   const latFraction = (ne.lat() - sw.lat()) / 360;
   const lngFraction = (ne.lng() - sw.lng()) / 360;
   // Calculate the viewport dimensions in pixels
   const mapWidth = map.getDiv().offsetWidth;
   const mapHeight = map.getDiv().offsetHeight;

   // Calculate the required zoom levels for latitude and longitude
   const latZoom = Math.log2(mapHeight / (256 * latFraction)) - zoomAdjustment;
   const lngZoom = Math.log2(mapWidth / (256 * lngFraction)) - zoomAdjustment;

   const calculatedZoom = Math.min(latZoom, lngZoom, maxZoom);
   const finalZoom = useLatLngZoom ? 
    (Math.min(latZoom, lngZoom) - .5) 
    : calculatedZoom - .5

   // Set the zoom level to the calculated value and center
   map.setZoom(finalZoom);

   if (latLngCenter) {
    map.setCenter(latLngCenter)
   } else {
     map.setCenter(center);
   }
}