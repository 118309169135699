<script setup lang="ts">
import { onBeforeMount, watch, nextTick } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { useAuthStore } from './store/authenticate';
import { ROUTE } from './types/enums';
import { useFormValidate } from './composables/useFormValidate';
import { HubSpotConfig } from './modules/hubspot/hubSpotUser';
import loadHubSpot from './modules/hubspot/loadHubSpot';
import { parseStringToJson } from './utils/parseStringToJson';

const router = useRouter()
const route = useRoute()

const userStore = useAuthStore()
const { resetError } = useFormValidate()

const getAuthUser = () => {
  const user = userStore.user || 
    parseStringToJson(localStorage.getItem('user'))
  if (! user) return  null
  return user
}

//eslint-disable-next-line complexity
onBeforeMount(async () => {
  loadHubSpot()

  await userStore.getSettings()
  const user = getAuthUser()

  if (user) {
    const hubspot = new HubSpotConfig(user)
    hubspot.bindVisitorIdentification()
  }
   
  if (! userStore.user) return 
  
  if (! userStore.user?.attributes.terms_accepted_at) {
    return router.replace({ name: ROUTE.TERMS_OF_USE })
  }
})

const routes = [
  '/explore', 
  '/settings/account',
  '/settings/subscription', 
  '/settings/referrals', 
  '/campaigns', 
  '/lothunt-report'
]

const removeMetaViewport = () => {
  nextTick(() => {
    const viewportMetaTag = document.querySelector('meta[name="viewport"]');
    if (viewportMetaTag) {
      if (routes.includes(route.path)) {
        viewportMetaTag.setAttribute('content', '')
      } else {
        viewportMetaTag.setAttribute('content', 
        'width=device-width, initial-scale=1.0, maximum-scale=1')
      }
    }
  })
}

watch(
  () => route.path,
  () => {
    resetError()
    removeMetaViewport()
    document.body.classList.remove('overflow-hidden')
  }
)
</script>

<template>
  <RouterView />
</template>

<style scoped></style>
