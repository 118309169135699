import axios, { AxiosError } from 'axios'
import * as Sentry from '@sentry/vue'
import { RESPONSE, ROUTE } from '@/types/enums'
import { useToast } from 'vue-toastification'
import { titleCase } from '@/utils/titleCase'
import router  from '../router'

const toast = useToast()

/**
 * If 451 Error occurs redirect to terms of use page to prompt
 * them to accept the terms of service and privacy policy.
 * This underscores the importance of ensuring that every user 
 * of LotHunt has agreed to our terms.
 * 
 * @param error AxiosError
 */
export const redirectToTermsOfService = (error: AxiosError) => {
  if (error.response?.status === RESPONSE.UNAVAILABLE_FOR_LEGAL_REASON) {
    window.location.href = window.location.origin + '/terms-of-use'
  }
}

export const redirectToPlans = async (error: AxiosError) => {
  if (error.response?.status === RESPONSE.PAYMENT_REQUIRED) {
    showToastError(error)
    await router.push({ name: ROUTE.SUBSCRIBE })
  }
}

/**
 * If response is unauthorized and the current page 
 * the user is in is not login or register then 
 * redirect the user to login page. 
 * 
 * This check is needed to prevent infinite redirect
 * from the navigation guard set on vue-router.
 */
export const redirectIfNotAuthPage = () => {
  const currentPath = window.location.pathname
  if (
    currentPath === '/login' || 
    currentPath === '/register' ||
    currentPath === '/reset-password'
  ) return 

  window.location.href = window.location.origin + '/login';
}

/**
 * Ensure that the settings will still be 
 * present after clearing the localStorage.
 */
export const clearLocalStorage = () => {
  const settings = localStorage.getItem('settings')
  localStorage.clear()

  if (settings !== null) {
    localStorage.setItem('settings', settings)
  }
}

export const showErrorToast = (error: AxiosError) => {
  if (! [
    /**
     * RESPONSE.BAD_REQUEST status code is being excluded
     * since this is mostly used to show error in forms.
     */
    RESPONSE.BAD_REQUEST, 
    RESPONSE.NOT_FOUND,
    RESPONSE.UNAUTHORIZED,
    RESPONSE.PAYMENT_REQUIRED,
    RESPONSE.UNAVAILABLE_FOR_LEGAL_REASON
  ].includes(error.response!.status) &&
  ! axios.isCancel(error)) {
    showToastError(error)
  }
}

const showToastError = (error: AxiosError) => {
  const errors = (error.response?.data as any)?.errors as objType

  if (! errors) {
    Sentry.captureMessage(`
      Error (Show Toast): Cannot parse response data.
      Error Data: ${JSON.stringify(error.response?.data)}`)
      
    return toast.error('Something wen\'t wrong')
  }

  const firstKey = Object.keys(errors)?.[0]
  const errorMsg = titleCase(firstKey) + ' ' + 
    titleCase((errors[firstKey] as unknown as string[])?.[0])
  return toast.error(errorMsg ?? 'Something went wrong')
}
