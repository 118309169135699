import { z } from 'zod'

export const UserDataValidation = z.object({
  name: z.string().trim().min(3, 'Name must have atleast 3 characters'),
  email: z.string().email().min(1, 'Email is required'),
  password: z.string().min(1, 'Password is required'),
  lots_flipped: z.string().refine(value => value !== '' || 
    z.enum(['None', '1-5', '5-20', '20+']).safeParse(value).success, 
    {
      message: 
        'Please select how many lots have you flipped',
    }
  ),
  terms_accepted: z.boolean().refine((val) => val, {
    message: 'Please read and accept the terms and conditions',
  })
})

export const UserLoginValidation = UserDataValidation.pick({
  email: true,
  password: true
})

export const ResetPasswordValidation = UserDataValidation.pick({
  email: true
})

export const UpdatePasswordValidation = UserDataValidation.pick({
  password: true
}).merge(
  z.object({ 
    verification_code: z.string()
      .min(6, 'Verification code must be 6 characters')
  })
)

//Type for updating the basic information of the user
export const UserUpdateValidation = z.object({
  name: z.string().trim().min(2, 'Name must have more than 2 characters'),
  pending_email: z.string().email().optional(),
  password: z.string().optional()
})

//Type for inviting a member
export const InviteUserValidation = UserDataValidation.pick({
  name: true,
  email: true
})

//Exported infered types from validation
export type InviteUserData = z.infer<typeof InviteUserValidation>
export type UserUpdateData = z.infer<typeof UserUpdateValidation>
export type UserRegistrationData = z.infer<typeof UserDataValidation>
export type UserLoginData = z.infer<typeof UserLoginValidation>
export type ResetPasswordData = z.infer<typeof ResetPasswordValidation>
export type UpdatePasswordData = z.infer<typeof UpdatePasswordValidation>

export type TermsOfUseUpdate = {
  terms_accepted: boolean,
  lots_flipped: UserRegistrationData['lots_flipped']
}
