<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router';
import { Icon } from '@iconify/vue/dist/iconify.js';
import CButton from './CButton.vue';
import HSChatNotif from './modal/HSChatNotif.vue';
import { processing } from '@/composables/useLoadingState';
import { useSidebar } from '@/composables/useSidebar';
import { items } from '@/lib/sidebar'
import { useAuthStore } from '@/store/authenticate';
import { LOADING, ROUTE } from '@/types/enums';
import SidebarTermsAndPolicy from './SidebarTermsAndPolicy.vue';

const emit = defineEmits<{
  (e: 'new-campaign'): void
}>()

const router = useRouter()

const { expanded, toggleSidebar } = useSidebar()
const { logout } = useAuthStore() 

const showHSModal = ref<boolean>(false)

const toggleHSChat = () => {
  if (window.HubSpotConversations) {
    window.HubSpotConversations.widget.open()
  } else {
    window.hsConversationsOnReady = [
      () => {
        window.HubSpotConversations.widget.open()
      }
    ];
    showHSModal.value = true
  }
}
</script>

<template>
  <div>
    <aside class="sidebar" :class="{'collapsed': !expanded}">
      <div 
        class="toggle"
        :class="{'toggle--expanded': expanded}"
        @click.prevent="toggleSidebar"
      >
        <Icon
          :icon="expanded ?
            'ic:outline-keyboard-arrow-left' : 
            'ic:outline-keyboard-arrow-right'"
          width="22"
          class="transition-all"
        />
      </div>
      <div>
        <div class="mb-10 px-6">
          <img
            v-show="expanded"
            src="@/assets/logo/lothunt-white-brown.svg"
            alt="LotHunt Logo"
            class="mt-12 mx-auto cursor-pointer"
            @click="router.push({ name: ROUTE.EXPLORE })"
          />
          <img
            v-show="!expanded"
            src="@/assets/markers/LotHunt-White-brown.png"
            alt="LotHunt Logo"
            class="mt-12 mx-auto cursor-pointer px-1.5"
            @click="() => router.push({ name: ROUTE.EXPLORE })"
          />
          <CButton
            block
            class="mt-10"
            color="light"
            data-cy="appSidebar_newCampaign"
            @click="emit('new-campaign')"
          >
            <Icon icon="ic:baseline-add" />
            <span :class="{'hidden': !expanded}">
              New Campaign
            </span>
          </CButton>
        </div>
        <div class="pt-2">
          <RouterLink
            v-for="(item, i) in items"
            :id="item.label"
            :key="i + '-' + item.label"
            :to="{ name: item.path }"
            :data-cy="`appSideBar_${item.label}`"
            class="text-gray-800 item-link"
          >
            <Icon :icon="item.icon" :width="20" />
            <span :class="{'hidden': !expanded}">
              {{ item.label }}
            </span>
          </RouterLink>
          <a
            href="#"
            type="button"
            class="item-link"
            :data-cy="`appSideBar_Help`"
            @click.prevent="toggleHSChat"
          >
            <Icon icon="ic:twotone-help" :width="20" />
            <span :class="{'hidden': !expanded}">
              Help
            </span>
          </a>
        </div>
      </div>
      <div class="w-full">
        <CButton
          color="transparent"
          class="btn-logout"
          :loading="processing === LOADING.LOG_OUT"
          block
          data-cy="appSidebar_logout"
          @click="logout"
        >
          <Icon
            icon="material-symbols-light:logout-rounded"
            class="-scale-x-1"
            :width="20"
          />
          <span :class="{'hidden': !expanded}">
            Logout
          </span>
        </CButton>
        <SidebarTermsAndPolicy
          class="privacy-container flex" 
          :class="{'hidden': !expanded}"
        />
      </div>
      <Teleport to="body">
        <HSChatNotif v-model="showHSModal" />
      </Teleport>
    </aside>
  </div>
</template>

<style scoped>
.privacy-container {
  @apply text-xs gap-x-2 items-center text-center my-6 justify-center  
}
 
.router-link-exact-active {
  @apply bg-primary-100 font-medium relative before:content-[''] 
  before:absolute before:left-0 before:h-full before:w-1 before:bg-primary-200
}

.toggle {
  @apply absolute top-10 -right-6 md:-right-[18px] z-50 rounded-full shadow 
  border cursor-pointer bg-white hover:bg-gray-100 transition-all
  ease-in-out p-1
}

.toggle--expanded {
  @apply -right-4
}

.router-link-active {
  @apply bg-primary-100 font-medium relative before:content-[''] 
  before:absolute before:left-0 before:h-full before:w-1 before:bg-primary-200
}

.btn-logout {
  @apply rounded-none mb-4 hover:bg-white/10 border-none hover:ring-0 
  ring-transparent hover:ring-offset-0 
}

.sidebar.collapsed {
  @apply w-24
}

.sidebar.collapsed a {
  @apply text-sm px-2.5
}

.sidebar.collapsed a svg {
  @apply mx-auto
}
</style>
