<script setup lang="ts">
import CButton from '../../CButton.vue';
import CRadio from '@/components/CRadio.vue';
import { Icon } from '@iconify/vue';
import { processing } from '@/composables/useLoadingState';
import { LOADING } from '@/types/enums';
import { useCreateCampaign } from '@/store/csv-campaign';

const emit = defineEmits<{
  (e: 'proceed'): void
  (e: 'previous'): void
}>()

const store = useCreateCampaign()
</script>

<template>
  <div>
    <h2 class="text-lg font-medium">
      Create Campaign
    </h2>
    <p class="text-gray-700">
      Choose how to proceed
    </p>
    <div class="flex flex-col gap-y-1 py-3">
      <div
        class="toggle-btn" 
        :class="{'toggle-btn--active': store.campaignType === 'explore'}"
        @click.prevent="store.campaignType = 'explore'"
      >
        <CRadio
          id="exploreMap"
          v-model="store.campaignType!"
          label="Find Lots to Mail"
          value="explore"
          data-cy="createCampaignOptions_exploreMap"
          @click.stop="store.campaignType === 'explore'"
        />
      </div>
      <div
        class="toggle-btn" 
        :class="{'toggle-btn--active': store.campaignType === 'upload'}"
        @click.prevent="store.campaignType = 'upload'"
      >
        <CRadio
          id="uploadCsv"
          v-model="store.campaignType!"
          label="Upload your own mailing list"
          value="upload"
          data-cy="createCampaignOptions_uploadCsv"
          @click.stop="store.campaignType = 'upload'"
        />
      </div>
    </div>
    <div class="flex gap-x-1 mt-2">
      <CButton
        data-cy="createCapaignOptions_back"
        block
        color="light"
        @click.prevent="emit('previous')"
      >
        <template #default>
          <div class="flex items-center gap-x-1.5">
            <Icon icon="ic:outline-arrow-back" /> 
            Back
          </div>
        </template>
      </CButton>
      <CButton
        block 
        :disabled="!store.campaignType"
        data-cy="createCampaignOptions_proceed"
        :loading="processing === LOADING.CREATE_CAMPAIGN"
        @click.prevent="emit('proceed')"
      >
        <template #default>
          <div class="flex gap-x-1.5">
            Proceed
          </div>
        </template>
      </CButton>
    </div>
  </div>
</template>

<style>
.toggle-btn {
  @apply px-3 py-4 border rounded-md cursor-pointer bg-white 
  hover:bg-gray-100 transition-all duration-200 ease-in-out
}

.toggle-btn--active {
  @apply border border-primary-200
}
</style>