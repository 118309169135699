import { MAPFILTERS, type MapFilterValue } from '@/types/enums'
import { ref } from 'vue'

const skipRerender = ref<boolean>(false)
const mapMarkerLegend = ref<MapFilterValue[]>([
  MAPFILTERS.LOTS, 
  MAPFILTERS.COMPS_SALE,
  MAPFILTERS.COMPS_SOLD 
])

export function useHandleState() {
  return { skipRerender, mapMarkerLegend }
}