import { createRouter, createWebHistory } from 'vue-router'
import { routes } from './routes'
import { ROUTE } from '@/types/enums'
import { getCurrentEnv } from '@/utils/getEnvApiUrl';
import { useAuthStore } from '@/store/authenticate';
import NProgress from 'nprogress'
import { parseStringToJson } from '@/utils/parseStringToJson';
import { clearLocalStorage } from '@/lib/handleErrorResponse';
import type { UserResponseData } from '@/types/api-response/user';

type RouteMeta = {
  requiresAuth?: boolean;
  title: string 
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes
})

NProgress.configure({
  minimum: 0.1,
  showSpinner: false
})

//eslint-disable-next-line complexity
router.beforeEach(async (to, from, next) => {
    NProgress.start()
    NProgress.set(0.1)
    document.title = 'LotHunt - ' + (to?.meta as RouteMeta)?.title 
    await getAuthenticatedUser()

    NProgress.set(0.5)
    if (to.path === '/admin') {
      location.href = getCurrentEnv() + 'admin'
    } else if (to.meta.requiresAuth && !localStorage.getItem('user')) {
      return next({ name: ROUTE.LOGIN })
    } else if (to.meta.requiresAuth === false && localStorage.getItem('user')) {
      return next({ name: ROUTE.HOME })
    } else {
      next()
    }
})

router.afterEach(() => {
  setTimeout(() => NProgress.done(), 300);
});

const getAuthenticatedUser = async () => {
  const authenticatedUser = localStorage.getItem('user')
  const parsedJson = parseStringToJson<UserResponseData>(authenticatedUser)
  if (! parsedJson) {
    clearLocalStorage()
  }

  const { getAuthUser } = useAuthStore()
  await getAuthUser()
}

export default router
