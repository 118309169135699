const getHubSpotScript = () => {
  return {
    'staging': import.meta.env.VITE_STAGING_HUBSPOT_SCRIPT,
    'development': import.meta.env.VITE_STAGING_HUBSPOT_SCRIPT,
    'production': import.meta.env.VITE_PRODUCTION_HUBSPOT_SCRIPT
  }[import.meta.env.MODE]
}

export default function () {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = getHubSpotScript()
  script.async = true 
  script.defer = true 
  script.id = 'hs-script-loader';
  document.body.appendChild(script)
}