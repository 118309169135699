<script setup lang="ts">
</script>

<template>
  <div class="privacy-container flex">
    <a
      href="https://lothunt.com/terms-of-service/" 
      target="_blank"
      class=" text-white text-nowrap text-center"
      data-cy="sidebarTermsAndPolicy_termsOfService"
    >
      Terms of Service
    </a>
    <span class="text-white">•</span>
    <a
      href="https://lothunt.com/privacy-policy/"
      target="_blank" 
      class=" text-white text-nowrap text-center"
      data-cy="sidebarTermsAndPolicy_privacypolicy"
    >
      Privacy Policy
    </a>
  </div>
</template>