import { ref, reactive, computed } from 'vue'
import type { MapBoundsFilter, MapFilters } from '@/types/mapFilters'
import type { CampaignsResponseData } from '@/types/api-response/campaigns'
import { dateUnitToDate } from '@/utils/dateUtils'
import { useHandleState } from './useHandleState'

const filters = reactive<MapFilters>({
  min_acres: null,
  max_acres: null,
  min_price: null,
  max_price: null, 
  sold_at_from: '',
  status: ['sold', 'for_sale'],
  county_id: ''
})

const mapBoundsFilter = reactive<MapBoundsFilter>({
  min_lat: null,
  max_lat: null,
  min_lng: null,
  max_lng: null,
})

const skipFetch = ref<boolean>(false)
/**
 * fetchData is used as a basis as to what needs to 
 * be fetched. There are watchers for filters and will 
 * trigger mostly to fetch lots, comps and count.
 */
const fetchData = ref<'lots' | 'comps' | 'count' | 'any'>('any')

export function useFilters() {
  //eslint-disable-next-line complexity
  const setFilterData = (campaign?: CampaignsResponseData) => {
    skipFetch.value = true
    filters.min_acres = campaign?.attributes.comp_filters?.min_acres! ?? null
    filters.max_acres = campaign?.attributes.comp_filters?.max_acres! ?? null
    filters.min_price = campaign?.attributes.comp_filters?.min_price! ?? null
    filters.max_price = campaign?.attributes.comp_filters?.max_price! ?? null
    filters.county_id = campaign?.attributes.lot_filters?.county_id! ?? null
    setFilterStatus(campaign?.attributes?.comp_filters?.status)
    if (campaign?.attributes.comp_filters?.sold_at_from) {
      filters.sold_at_from = campaign?.attributes.comp_filters?.sold_at_from
    } else {
      filters.sold_at_from = dateUnitToDate({ date: 3, unit: 'years' })
    }
    fetchData.value = 'any'
  }

  const state = useHandleState()

  const setFilterStatus = (status?: 'sold' | 'for_sale') => {
    filters.status = []
    if (! status) {
      filters.status = ['for_sale', 'sold']
    } else {
      filters.status = [status]
      //Set state for map marker legend
      if (status === 'for_sale') {
        state.mapMarkerLegend.value.splice(2, 1)  
      } else {
        state.mapMarkerLegend.value.splice(1, 1)  
      }
    }
  }

  const getCompStatus = () => {
    if (filters.status.length === 0) return null
    if (filters.status.length === 2) return null 
    return filters.status?.[0]
  }

  //eslint-disable-next-line complexity
  const checkIsFilterValid = () => {
    if (filters.max_acres && filters.min_acres) {
      if (+filters.max_acres < +filters.min_acres) {
        return false
      }
    }
    if (filters.max_price && filters.min_price) {
      if (+filters.max_price < +filters.min_price) {
        return false
      }
    }
    return true
  }

  /**
   * DO NOT CLEAR THE EXCLUDED APNS
   * Users spend hours scrubbing lots and if they need to redo that 
   * work, then they will not be happy.
   */
  const clearFilters = () => {
    filters.min_acres = null
    filters.max_acres = null
    filters.min_price = null
    filters.max_price = null
    filters.status = ['sold', 'for_sale']
    filters.sold_at_from = ''
    filters.county_id = ''
  }

  //eslint-disable-next-line complexity
  const getAppliedFiltersCount = computed(() => {
    let total = 0

    for (const key of Object.keys(filters)) {
      const typedKey = key as keyof typeof filters
      if (
          ! Array.isArray(filters[typedKey]) &&
          (filters[typedKey] !== null &&
          filters[typedKey] !== '' &&
          filters[typedKey] !== undefined)
        ) {
          total += 1
        }
    }

    return total
  })

  return { 
    checkIsFilterValid, 
    clearFilters,
    filters, 
    fetchData,
    getAppliedFiltersCount,
    getCompStatus,
    mapBoundsFilter,
    setFilterData, 
    setFilterStatus, 
    skipFetch 
  }
}