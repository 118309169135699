import { ref } from 'vue'
import { defineStore } from 'pinia';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import api from '@/lib/axios'; 
import type { 
  UserRegistrationData, 
  UserLoginData, 
  ResetPasswordData, 
  UpdatePasswordData 
} from '@/types/user'
import { 
  ResetPasswordValidation, 
  UpdatePasswordValidation, 
  UserDataValidation, 
  UserLoginValidation 
} from '@/types/user';
import { useFormValidate } from '@/composables/useFormValidate';
import { LOADING, RESPONSE, ROUTE } from '@/types/enums';
import type { UserApiResponse, UserData, UserResponseData } 
  from '@/types/api-response/user';
import { processing } from '@/composables/useLoadingState';
import { useUserStore } from './user';
import { useSettings } from '@/composables/useSettings';
import { clearLocalStorage } from '@/lib/handleErrorResponse';
import { HubSpotConfig } from '@/modules/hubspot/hubSpotUser';

export const useAuthStore = defineStore('authentication', () => {
  const toast = useToast()
  const router = useRouter()
  const zod = useFormValidate()
  const success = ref<boolean>(false)
  const user = ref<UserData>(null)

  const logout = async () => {
    processing.value = LOADING.LOG_OUT
    const userData = user.value || 
      JSON.parse(localStorage.getItem('user') || '{}') 
    //Clear HubSpot User
    const hubspot = new HubSpotConfig(userData)
    await hubspot.bindVisitorIdentification()   

    const { status } = await api.delete('sessions')

    if (
        status === RESPONSE.NO_CONTENT ||
        status === RESPONSE.NOT_FOUND
      ) {
      clearLocalStorage()
      updateUserSubscription(null)
      router.replace({ name: ROUTE.LOGIN })
    }
  }

  const login = async (form: UserLoginData) => {
    zod.validate(form, UserLoginValidation)
    if (! zod.isValid.value) return
    processing.value = LOADING.LOGIN 
    const { data, status } = await api.post<UserApiResponse>('sessions', form)
    
    if (status === RESPONSE.UNAUTHORIZED) {
      zod.setRequestError(data?.errors)
    }
    if (status === RESPONSE.SUCCESS) {
      localStorage.setItem('user', JSON.stringify(data?.data))
      updateUserData(data?.data)
      const hubspot = new HubSpotConfig(data?.data)
      await hubspot.bindVisitorIdentification()    
      router.replace({ name: ROUTE.EXPLORE })
    }
  }

  const register = async (userPayload: UserRegistrationData) => {
    zod.validate(userPayload, UserDataValidation)
    if (! zod.isValid.value) return 

    processing.value = LOADING.REGISTER
    const { data, status } = await api.post<UserApiResponse>('users', { 
      user: userPayload 
    })

    if (status === RESPONSE.SUCCESS) {
      success.value = true
      user.value = data.data
    }
  }

  const verifyRequestStatus = ref<boolean>(false)
  const { 
    updateUserData, 
    updateIncludedUser, 
    updateUserSubscription 
  } = useUserStore()

  const verifyEmail = async (code: string): 
    Promise<boolean | undefined> => {
    processing.value = LOADING.VERIFY
    const response = await api.post<UserApiResponse>('users/verify', {
      user: {
        email_verification_code: code,
        id: user.value?.id
      }
    })

    if (response.status === RESPONSE.SUCCESS) {
      await getSettings()
      success.value = true
      user.value = response.data.data
      localStorage.setItem('user', JSON.stringify(response.data.data))
      updateUserData(response.data.data)
      verifyRequestStatus.value = true
      return true
    }
  }

  const resetEmail = ref<string>('')
  const forgotPassword = async (form: ResetPasswordData): 
    Promise<boolean | undefined> => {
      zod.validate(form, ResetPasswordValidation)
      if (! zod.isValid.value) return

      processing.value = LOADING.FORGOT_PASSWORD
      const { status } = await api.post('password-reset', form)

      if (status === RESPONSE.NO_CONTENT) {
        resetEmail.value = form.email 
        return true
      }
  }

  const updatePassword = async (payload: UpdatePasswordData):
    Promise<boolean | undefined> => {
      zod.validate(payload, UpdatePasswordValidation)
      if (! zod.isValid.value) return

      processing.value = LOADING.UPDATE_PASSWORD
      const { status } = await api.put('password-reset', {
        email: resetEmail.value, ...payload
      })

      if (status === RESPONSE.SUCCESS || status === RESPONSE.NO_CONTENT) {
        toast.success('Password has been updated successfully')
        await getAuthUser()
        await router.replace({ name: ROUTE.EXPLORE })
      }
  }

  const resendVerification = async (): Promise<boolean | undefined> => {
    const response = await api.post('users/send-email-verification', {
      user: { email: user.value?.attributes.email }
    })
    
    if (response.status === RESPONSE.NO_CONTENT) {
      toast.success('Verification code has been sent to your email')
      return true
    }
  }

  const getAuthUser = async (): Promise<UserResponseData | undefined> => {
    processing.value = LOADING.GET_AUTH_USER
    const response = await api.get<{
      data: UserResponseData,
      included: UserResponseData[]
    }>('users/me')

    if (response.status === RESPONSE.SUCCESS) {
      user.value = response.data.data
      localStorage.setItem('user', JSON.stringify(user.value))
      updateUserData(response.data.data)
      updateIncludedUser(response.data.included)
      const subscription: any = response.data.included.filter(
        val => val.type === 'account')?.[0]
      const plan: any = response.data.included.filter(
        val => val.type === 'plan')?.[0]
      updateUserSubscription(subscription, plan)
      return response.data.data
    }
  }

  const settings = useSettings()
  const getSettings = async () => {
    const response = await api.get('settings')
    if (response.status === RESPONSE.SUCCESS) {
      localStorage.setItem('settings', JSON.stringify(response.data))
      settings.settingsData.value = response.data
    }
  }

  return { 
    errors: zod.errors, forgotPassword, getAuthUser, getSettings,
    login, logout, register,  resetEmail, resendVerification, success, 
    updatePassword, user, verifyEmail, verifyRequestStatus
  }
})