const getGTagID = () => {
  return {
    'production': import.meta.env.VITE_GTAG_ID,
    'staging': import.meta.env.VITE_STAGING_GTAG_ID,
    'development': import.meta.env.VITE_DEV_GTAG_ID
  }[import.meta.env.MODE]
}

export const gTagConfig = {
  config: {
    id: getGTagID(),
  }
}