<script setup lang="ts">
import { ref } from 'vue'
import { useRoute } from 'vue-router';
import AppSidebar from '@/components/AppSidebar.vue';
import NewCampaignModal from '@/components/campaign/NewCampaignModal.vue';
import { useSidebar } from '@/composables/useSidebar';
import { ROUTE } from '@/types/enums';

const createModal = ref<boolean>(false)

const { expanded } = useSidebar()
const route = useRoute()
</script>

<template>
  <div>
    <KeepAlive>
      <AppSidebar
        v-if="route.name !== ROUTE.ACTIVATE_CAMPAIGN"
        @new-campaign="createModal = true"
      />
    </KeepAlive>
    <div 
      class="transition-all ease-in-out duration-200"
      :class="route.name === ROUTE.ACTIVATE_CAMPAIGN ? 
        '' : expanded ? 'ml-[238px]' : 'ml-[94px]'"
    >
      <slot>
        <RouterView />
      </slot>
    </div>
    <Teleport to="body">
      <NewCampaignModal v-model="createModal" />
    </Teleport>
  </div>
</template>
