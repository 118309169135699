import { ref } from 'vue'
import type { PricingPlanContent } from '@/types/pricingPlan'

export function usePricingPlan () {
  const pricingPlans = ref<PricingPlanContent[]>([
    {
      type: 'Basic',
      description: `Get started with all the tools needed
      to acquire land.`,
      price: 99,
      features: [
        `2,000 included records/month`,
        `+5¢ per extra record`,
        `Only $0.23 per 1 sheet non-window mail*`,
        `Access to LotHunt advanced reports`,
        `Unlimited Research & Exploring`,
        `Template Editing`,
        `Advanced Filters`,
        `Scrubbing`,
        `Land Valuations`,
        `Unlimited Comp Reports`,
      ],
    },
    {
      type: 'Professional',
      description: `For when you get serious on your land journey`,
      price: 249,
      features: [
        `6,000 included records/month`,
        `+4¢ per extra record`,
        `Only $0.21 per 1 sheet non-window mail*`,
        `All features in Basic`,
        `A/B Tests`,
      ],
    },
    {
      type: 'Business',
      description: `Dominate the market with the lowest cost per mail`,
      price: 599,
      features: [
        `20,000 included records/month`,
        `+3¢ per extra record`,
        `Only $0.19 per 1 sheet non-window mail*`,
        `All features in Professional`,
      ],
    },
    {
      type: 'Enterprise',
      description: `Speak to us anytime to discuss volume discounts. 
      Starts at:`,
      price: 2000,
      features: [
        `All features in Business`,
        `Special high volume discounts`,
        `Priority support`,
      ],
    },
  ]);
  
  const getEnterprisePlan = 
    pricingPlans.value.filter(plan =>
      plan.type === 'Enterprise')?.map(enterprise => ({
        id: '',
        attributes: {
          name: enterprise.type,
          price: enterprise.price,
          interval: 'monthly'
        },
        ...enterprise,
        type: 'plan'
      }))?.[0]
  
  return {
    pricingPlans,
    getEnterprisePlan
  }
}