export function parseStringToJson<T>(
  s: string | null
): T | null {
  if (! s) return null

  try {
    return JSON.parse(s) as T
  } catch (e) {
    //String is not a valid json object
    return null
  }
}