<script setup lang="ts">
import { Icon } from '@iconify/vue';
import CModal from '../CModal.vue';

const model = defineModel<boolean>()
</script>

<template>
  <CModal v-model="model" data-cy="hsChatNotif" no-padding>
    <div class="flex gap-x-4 pt-7 pb-8 items-start px-6">
      <div class="bg-red-700/10 mt-1 p-2 rounded-full">
        <Icon
          icon="mdi:warning-circle-outline"
          :width="20"
          class="text-red-600 animate-pulse"
        />
      </div>
      <div class="max-w-md pr-4">
        <p class="font-medium text-lg mt-1">
          Chat Unavailable
        </p>
        <p class="text-gray-800 mt-1">
          Unfortunately, we weren’t able to open the instant 
          chat on our website. This could be because of tracking 
          blocking installed in your browser.
        </p>
        <p class="text-gray-800 mt-2">
          But we’re still just one email away. 
          <span class="font-medium">
            Please email us at support@lothunt.com,
          </span> 
          and we’ll do our best to get back 
          to you as soon as possible.
        </p>
      </div>
    </div>
  </CModal>
</template>