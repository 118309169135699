import type { SidebarItems } from '@/types/components/Sidebar'
import { ROUTE } from '../types/enums'

export const items: SidebarItems[] = [
  {
    path: ROUTE.EXPLORE,
    icon: 'ic:baseline-map',
    label: 'Explore'
  },
  {
    path: ROUTE.CAMPAIGNS,
    icon: 'ic:baseline-email',
    label: 'Campaigns'
  },
  {
    path: ROUTE.LOTHUNT_REPORT,
    icon: 'ic:insert-drive-file',
    label: 'LotHunt Report'
  },
  {
    path: ROUTE.SETTINGS,
    icon: 'ic:baseline-settings',
    label: 'Settings'
  },
]