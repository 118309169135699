import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { defineStore } from 'pinia';
import { useToast } from 'vue-toastification';
import api from '@/lib/axios';
import { processing } from '@/composables/useLoadingState';
import { LOADING, RESPONSE, ROUTE } from '@/types/enums';
import { useFormValidate } from '@/composables/useFormValidate';
import type { UserPlan, UserApiResponse, UserData, UserResponseData,
  UserSubscription,
  UserReferral
} from '@/types/api-response/user';
import { PasswordValidation, type ChangePassword } from '@/types/password';
import { 
  UserUpdateValidation, 
  type UserUpdateData, 
  type InviteUserData,
  InviteUserValidation,
  type TermsOfUseUpdate
} from '@/types/user';
import { useSettings } from '@/composables/useSettings';
import * as Sentry from '@sentry/vue'
import { parseStringToJson } from '@/utils/parseStringToJson';

export const useUserStore = defineStore('user', () => {
  const zod = useFormValidate()
  const toast = useToast()
  const router = useRouter()
  const settings = useSettings()

  const user = ref<UserData>(
    parseStringToJson<UserData>(localStorage.getItem('user')))
  const includedUsers = ref<UserResponseData[]>([])
  const userPlan = ref<UserPlan | null>(null)
  const userReferral = ref<UserReferral | null>(null)
  const activeSubscription = ref<UserSubscription | null>()

  const getActiveSubscription = computed(() => {
      const monthSub = settings.monthlyPlans.value.find(monthyPlan => 
      monthyPlan.id === activeSubscription?.value?.attributes.plan_id)
  
      const annualSub = settings.yearlyPlans.value.find(yearlyPlan => 
      yearlyPlan.id === activeSubscription.value?.attributes.plan_id)

      if (!monthSub && !annualSub) {
        return userPlan.value
      }
      return monthSub ? monthSub : annualSub
  })

  const updatePassword = async (form: ChangePassword): 
    Promise<boolean | undefined> => {
      zod.validate(form, PasswordValidation)
      if (! zod.isValid.value) return 
      
      processing.value = LOADING.UPDATE_PASSWORD
      const response = await api.put('users', { user: form })
      
      if (response.status === RESPONSE.SUCCESS) {
        toast.success('Password has been updated')
        return true
      }
  }

  const updateUserInfo = async (
      form: PartialBy<UserUpdateData, 'pending_email'>
    ): Promise<boolean | undefined> => {
      zod.validate(form, UserUpdateValidation)
      if (! zod.isValid.value) return 

      processing.value = LOADING.UPDATE_USER
      const response = await api.put<UserApiResponse>('users', { user: form })

      if (response.status === RESPONSE.SUCCESS) {
        user.value = response.data.data
        toast.success('Account information has been updated')
        return true
      }
  }

  const inviteMember = async (form: InviteUserData):
    Promise<boolean | undefined> => {
      zod.errorBag.value = 'invite'
      zod.validate(form, InviteUserValidation) //Resets error bag
      if (! zod.isValid.value) return 
      
      processing.value = LOADING.INVITE_MEMBER
      const response = await api.put('accounts', {
        users_attributes: [{ ...form, account_role: 'member', active: true }]
      })

      if (response.status === RESPONSE.SUCCESS) {
        updateIncludedUser(response.data.included)
        return true
      }
  }

  const setActiveStatus = async (
    userData: PartialBy<InviteUserData, 'email'> & 
    { id: string; active: boolean }
  ): Promise<boolean | undefined> => {
    processing.value = LOADING.SET_MEMBER_STATUS
    const response = await api.put('accounts', {
      users_attributes: [{ ...userData, account_role: 'member' }]
    })

    if (response.status === RESPONSE.SUCCESS) {
      updateIncludedUser(response.data.included)
      return true
    }
  }

  const setAccountOwnership = async (
    userData: PartialBy<InviteUserData, 'email'> & 
    { id: string; }
  ): Promise<boolean | undefined> => {
    const ownerAccount = {
      id: user.value?.id,
      active: user.value?.attributes.active,
      name: user.value?.attributes.name,
      account_role: 'member'
    }
    
    processing.value = LOADING.CHANGE_OWNERSHIP
    const response = await api.put('accounts', {
      users_attributes: [
        ownerAccount,
        { ...userData, account_role: 'owner' }
      ]
    })
    
    if (response.status === RESPONSE.SUCCESS) {
      updateIncludedUser(response.data.included)
      return true
    }
  }
  
  const updateUserTermsofUse = async ({
    terms_accepted, lots_flipped
  }: TermsOfUseUpdate) => {
    processing.value = LOADING.TERMS_ACCEPTED
    const response = await api.put<UserApiResponse>('users', {
      user: { terms_accepted, lots_flipped }
    })
    
    if (response.status === RESPONSE.SUCCESS) {
      user.value = response.data.data
      localStorage.setItem('user', JSON.stringify(user.value))
      await router.replace({ name: ROUTE.EXPLORE })
    }
  }
  
  const updateUserData = (userData: UserData) => {
    user.value = userData
    Sentry.setUser({
      user_id: user.value?.id,
      username: user.value?.attributes.name, 
      email: user.value?.attributes.email,
      account_role: user.value?.attributes.account_role,
      account_id: user.value?.relationships?.account?.data?.id
    })
  }

  const updateIncludedUser = (usersData: UserResponseData[]) => {
    includedUsers.value = usersData?.filter(user => user.type === 'user')
    userReferral.value = usersData?.filter(user => 
      user.type === 'account')?.map(data => ({
        referral_link: data.attributes.referral_link,
        referral_clicks: data.attributes.referral_clicks,
        referral_signups: data.attributes.referral_signups
      }))?.[0]
  }

  const updateUserSubscription = (
    subscription: UserSubscription | null, plan?: UserPlan | null) => {
      activeSubscription.value = subscription
      if (plan) {
        userPlan.value = plan
      }
  }

  return {
    activeSubscription,
    errors: zod.errors,
    getActiveSubscription,
    inviteMember,
    updatePassword,
    updateUserInfo,
    user,
    userPlan,
    userReferral,
    includedUsers,
    updateUserData,
    updateIncludedUser,
    setAccountOwnership,
    setActiveStatus,
    updateUserSubscription,
    updateUserTermsofUse,
  }
})