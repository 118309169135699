<script setup lang="ts">
import FormInput from '../../forms/FormInput.vue'
import CButton from '../../CButton.vue';
import { Icon } from '@iconify/vue';
import { useCreateCampaign } from '@/store/csv-campaign';

const emit = defineEmits<{
  (e: 'next-step', campaignName: string): void
}>()

const createCampaign = useCreateCampaign()

const nextStep = () => {
  if (!createCampaign.campaignName) return 
  emit('next-step', createCampaign.campaignName)
}
</script>

<template>
  <div class="p-1.5">
    <h2 class="text-lg font-medium">
      Create Campaign
    </h2>
    <p class="text-gray-700">
      Please enter a campaign name to begin
    </p>
    <form @submit.prevent="nextStep">
      <FormInput
        id="campaignName"
        v-model="createCampaign.campaignName"
        input-ref="campaignNameRef"
        label="Campaign Name"
        class="mt-4"
        autofocus
        data-cy="createCampaign_name"
        placeholder="Enter campaign name"
        required
      />
      <CButton
        text="Next Step"
        type="submit"
        block
        class="mt-5"
        data-cy="createCamapign_nextStep"
        :disabled="!createCampaign.campaignName?.trim()"
      >
        <template #default>
          <div class="flex items-center gap-x-2">
            Next Step
            <Icon icon="ic:outline-arrow-forward" /> 
          </div>
        </template>
      </CButton>
    </form>
  </div>
</template>