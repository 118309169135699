import { ref } from 'vue'
import { titleCase } from '@/utils/titleCase';
import { ZodSchema, type ZodFormattedError } from 'zod'

type Error = {
  [key: string | number]: string | null | undefined;
}

const errors= ref<Error>({})
const errorBag = ref<string>('')

export function useFormValidate() {
  const isValid = ref<boolean>(false)

  const validate = <T>(data: T, zodPayload: ZodSchema) => {
    resetError()
    const validated = zodPayload.safeParse(data)

    if (validated.success) { isValid.value = true }

    if (!validated.success) {
      const formattedError = validated.error.format()
      setZodErrors(formattedError as ZodFormattedError<objType>)
    }
    //Reset Error Bag
    errorBag.value = ''
  }

  const resetError = () => {
    isValid.value = false 
    errors.value = {}
  }

  const setZodErrors = (formattedError: ZodFormattedError<objType>) => {
    for (const key in formattedError) {
      if (errorBag.value) {
        errors.value[errorBag.value + '_' + key] = 
          formattedError[key]?._errors?.[0]
      } else {
        errors.value[key] = formattedError[key]?._errors?.[0]
      }
    }
    errorBag.value = ''
  }

  const setRequestError = (payload: Error) => {
    for (const key in payload) {
      if (errorBag.value) {
        errors.value[errorBag.value + '_' + key] = 
          `${titleCase(key)} ${payload[key]?.[0]}`
      } else {
        errors.value[key] = `${titleCase(key)} ${payload[key]?.[0]}`
      }
    }
    errorBag.value = ''
  }
  
  return { 
    errors, 
    errorBag,
    isValid, 
    resetError,
    setRequestError, 
    validate 
  }   
}