import api from '@/lib/axios';
import type { UserData } from '@/types/api-response/user';

export interface HSConfig {
  bindVisitorIdentification(token: string): void
  fetchIdentificationToken(): void
}

export class HubSpotConfig implements HSConfig {
  protected _hsConversationsSettings?: objType | null;
  protected _user: UserData

  constructor(
    user: UserData,
    conversationSettings?: objType | null
  ) {
    if (window?.Cypress) return
    this._hsConversationsSettings = conversationSettings ?? 
      { loadImmediately: false }
    this._user = user

    if (window.HubSpotConversations) {
      window.hsConversationsSettings = this._hsConversationsSettings!
    } else {
      window.hsConversationsOnReady = [
        () => {
          window.hsConversationsSettings = this._hsConversationsSettings!
        }
      ]
    }
  }

  async fetchIdentificationToken(): Promise<string | null> {
    const res = await api.post<{ token: string }>(`hubspot/identify`)
    return res.data?.token
  }

  //eslint-disable-next-line complexity
  async bindVisitorIdentification() {
    if (window?.Cypress) return
    const token = await this.fetchIdentificationToken()
    const email = this._user?.attributes.email 

    if (token && email && typeof email === 'string') {
      this._hsConversationsSettings = {
        identificationEmail: this._user?.attributes.email,
        identificationToken: token
      }
    } else {
      this._hsConversationsSettings = {
        identificationEmail: '',
        identificationToken: token
      }
    }
    
    if (window.HubSpotConversations) {
      window.hsConversationsSettings = this._hsConversationsSettings!
      window.HubSpotConversations.clear({ resetWidget: true })
    } else {
      window.hsConversationsOnReady = [
        () => {
          window.hsConversationsSettings = this._hsConversationsSettings!
          window.HubSpotConversations.clear({ resetWidget: true })
        }
      ]
    }
  }
}