import axios, { AxiosError } from 'axios'
import * as Sentry from '@sentry/vue'
import { RESPONSE } from '@/types/enums';
import { useFormValidate } from '@/composables/useFormValidate';
import { processing, activeRequest } from '@/composables/useLoadingState';
import { getCurrentEnv } from '@/utils/getEnvApiUrl';
import { useToast } from 'vue-toastification';
import { 
  showErrorToast, 
  redirectToPlans, 
  redirectIfNotAuthPage, 
  redirectToTermsOfService,
  clearLocalStorage,
} from '../handleErrorResponse';

const api = axios.create({
  baseURL: getCurrentEnv(),
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'X-Request-With': 'XMLHttpRequest'
  }
})

const zod = useFormValidate()
const toast = useToast()

const setProcessingNull = () => {
  if (activeRequest.value === 0) {
    processing.value = null
  }
}

api.interceptors.request.use(config => {
  activeRequest.value++
  return config
}, error => {
  return Promise.reject(error)
})

api.interceptors.response.use(function (response) {
  /**
   *  Set processing value to null after the
   *  request has been processed either successful or not.
   */
  activeRequest.value--
  setProcessingNull()
  return response;
  //eslint-disable-next-line complexity
}, function (error: Error | AxiosError) {
  activeRequest.value--
  setProcessingNull()
  
  if (axios.isAxiosError(error)) {
    if (error?.code === AxiosError.ERR_CANCELED) return error

    if (error?.code === AxiosError.ERR_NETWORK) {
      Sentry.captureMessage('Error: Can\'t connect to the api server')
      return toast.error('Can\'t connect to the server')
    }

    if (error.response?.status === RESPONSE.UNAUTHORIZED) {
      clearLocalStorage()
      redirectIfNotAuthPage()
    }

    redirectToPlans(error)
    redirectToTermsOfService(error)

    if ([
      RESPONSE.BAD_REQUEST, 
      RESPONSE.NOT_FOUND
    ].includes(error.response!.status)) {
      const { data } = error.response!
      zod.setRequestError(data?.errors)
    }

    showErrorToast(error)

    return error.response;
  } else {
    Sentry.captureException(error) //Log to Sentry
  }
});

export default api
