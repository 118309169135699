import { ref } from 'vue'

const isExpanded = localStorage.getItem('expanded')

const expanded = ref<boolean | string | null>(
  isExpanded === 'false' ? false : true
)

export function useSidebar () {
  const toggleSidebar = () => {
    expanded.value = !expanded.value
    localStorage.setItem('expanded', expanded.value.toString())
  }

  return { expanded, toggleSidebar }
}