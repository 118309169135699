import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import * as Sentry from '@sentry/vue';

import Toast from 'vue-toastification';
import { toastOptions } from './modules/vue-toastification'
import 'vue-toastification/dist/index.css';

import '@/assets/main.css'

import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

import { autoAnimatePlugin } from '@formkit/auto-animate/vue'

import VueGtag from 'vue-gtag'
import { gTagConfig } from './modules/gtag'

import 'nprogress/nprogress.css';

const app = createApp(App)

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  trackComponents: true,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 0.1,
  environment: import.meta.env.MODE,
  tracePropagationTargets: [
    'localhost', 
    /https:\/\/staging-app-cdk6bnh5bwf\.lothunt\.com\//,
    /https:\/\/app\.lothunt\.com\//
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

app.use(createPinia())
app.use(router)
app.use(Toast, toastOptions);
app.use(FloatingVue)
app.use(autoAnimatePlugin)
app.use(VueGtag, gTagConfig)

app.mount('#app')
